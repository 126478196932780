const App = {
  data: () => ({
    showModal: false,
    currentChapterId: 1,
    currentLessonId: 2,
    currentLessonSecond: 300,
    currentLessonUrl: null,
    currentPosterUrl: null,
    currentUserId: 235,
    videoPlayer: null,
    videoOptions: {
      autoplay: true,
      muted: false,
      controls: true,
      aspectRatio: '640:400',
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      fluid: false,
      fill: true,
      responsive: false,

      poster: 'assets/images/posters/poster-1.jpg',
      preload: true,
      sources: [
        {
          src: 'assets/media/CH_04_Intro_01.mp4',
          type: 'video/mp4',
        },
      ],
      plugins: {
        seekButtons: {
          forward: 5,
          back: 5,
        },
      },
      // remainingTime: false,
      // durationDisplay: true,
      // captionsButton: true,
      // descriptionsButton: true,
      // children: {
      //   controlBar: {},
      // },
      controlBar: {
        children: [
          'progressControl',
          'playToggle',
          'volumePanel',
          'playbackRateMenuButton',
          'currentTimeDisplay',
          'durationDisplay',
          'fullscreenToggle',
          // 'captionsButton',
          // 'descriptionsButton',
        ],
        volumePanel: {
          inline: false,
          vertical: true,
        },
        progressControl: {
          keepTooltipsInside: true,
        },
        // fullscreenToggle: false,
        remainingTimeDisplay: false,
        captionsButton: true,
        // descriptionsButton: false,
        // children: {
        //   captionsButton: true,
        //   descriptionsButton: true,
        // },
        // children: [
        //   'progressControl',
        //   'playToggle',
        //   // 'seekButtons',
        //   'playbackRateMenuButton',
        //   // 'forward',
        //   'volumeMenuButton',
        //   'currentTimeDisplay',
        //   'durationDisplay',
        //   // 'timeDivider',
        //   // 'remainingTimeDisplay',
        //   'volumeControl',
        //   'fullscreenToggle',
        // ],
      },
    },
    videoIsPlaying: false,
    videoPlayNext: false,
    videoPlayNextTimeout: 5,
    videoPlayNextTimer: 0,
    videoPlayNextInterval: 0,
    userIsActive: true,
    // isDesktop: true,
    sidebarVisible: true,
    isExpandedView: false,
    popperIsHovered: false,
    currentDataTab: 1,
    courseTitle: 'Learning Course Title 1',
    // rateModalVisible: false,
    summary: {
      label: 'About this course',
      shortDescription:
        'Short description lorem ipsum, dolor sit amet consectetur adipisicing elit.',
      stats: {
        statsLabel: 'By the numbers',
        statsGroups: [
          {
            statsGroupTitle: 'Main Stats',
            items: [
              {
                statTitle: 'Skill level',
                statValue: 'All Levels',
              },
              {
                statTitle: 'Students',
                statValue: '81311',
              },
              {
                statTitle: 'Languages',
                statValue: 'English',
              },
              {
                statTitle: 'Captions',
                statValue: 'Yes',
              },
            ],
          },
          {
            statsGroupTitle: 'Additional Stats',
            items: [
              {
                statTitle: 'Lectures',
                statValue: '28',
              },
              {
                statTitle: 'Video',
                statValue: '2.5 total hours',
              },
            ],
          },
        ],
      },
      features: {
        featuresLabel: 'Features',
        featuresContent:
          "Available on <a href=''>iOS</a> and <a href=''>Android</a>",
      },
      description: {
        descriptionLabel: 'Description',
        descriptionContent: [
          '<p>This Javascript Framework called Vue.js, or Vue has become very popular, especially in the Laravel community. Vue has adopted and improved many features of the other bigger frameworks like Angular, React and Ember.</p>',
          '<p>With Vue you can be up and running within 3 minutes. We can link to a local Vue script, use a CDN or just use the amazing CLI to create single file applications with ease.</p>',
          '<p>No more do we need to rely on jQuery to create really cool functionalities since Vue makes it a lot easier to play around with the DOM and create really amazing functionalities in our app with less code. </p>',
          '<p>Learning to use this Javascript framework has become important since developers are always in need of fast, flexible and easy to implement Frameworks.. </p>',
          '<p>This course covers the main Vue features so that anybody can be up and running with it in no time. </p>',
          '<p>Here are some of the things we are covering </p>',
          '<ul><li>Data binding</li><li>Control Structures</li><li>Conditionals</li><li>Lists redering</li><li>Computed properties</li><li>Components</li><li>Routing</li><li>HTTP</li><li>and more.....</li></ul>',
        ],
      },
      instructor: {
        instructorLabel: 'Instructor',
        instructorID: 234,
      },
    },
    summaryCollapsed: true,
    announcements: [
      {
        id: 1,
        date: '2021-01-24T18:25:43.511Z',
        authorId: 234,
        annTitle:
          'Iure, at voluptatem vel velit ipsam molestiae accusantium officiis',
        annContent: [
          '<p>This <a href="">Javascript Framework</a> called Vue.js, or Vue has become very popular, especially in the Laravel community. Vue has adopted and improved many features of the other bigger frameworks like Angular, React and Ember.</p>',
          '<p>With Vue you can be up and running within 3 minutes. We can link to a local Vue script, use a CDN or just use the amazing CLI to create single file applications with ease.</p>',
          '<p>No more do we need to rely on jQuery to create really cool functionalities since Vue makes it a lot easier to play around with the DOM and create really amazing functionalities in our app with less code. </p>',
          '<p>Learning to use this Javascript framework has become important since developers are always in need of fast, flexible and easy to implement Frameworks.. </p>',
          '<p>This course covers the main Vue features so that anybody can be up and running with it in no time. </p>',
          '<p>Here are some of the things we are covering </p>',
          '<ul><li>Data binding</li><li>Control Structures</li><li>Conditionals</li><li>Lists redering</li><li>Computed properties</li><li>Components</li><li>Routing</li><li>HTTP</li><li>and more.....</li></ul>',
        ],
        comments: [
          {
            id: 25,
            date: '2021-01-25T18:25:43.511Z',
            authorId: 235,
            commentContent:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quo minus enim cum perferendis officiis blanditiis rem vel, ipsam consectetur suscipit earum qui, necessitatibus molestiae veniam sunt similique quas debitis.',
          },
          {
            id: 26,
            date: '2021-01-26T18:25:43.511Z',
            authorId: 234,
            commentContent:
              'A esse soluta saepe illo consequatur dolorum sunt adipisci aliquid maiores accusamus pariatur et delectus consectetur, eaque eos iste commodi tenetur sapiente.',
          },
        ],
        annCommentHelpVisible: false,
        commentsVisible: false,
      },
      {
        id: 2,
        date: '2021-12-25T18:25:43.511Z',
        authorId: 234,
        annTitle: 'Merry Christmas!',
        annContent: [
          '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias adipisci quo quaerat laborum. Blanditiis id corporis atque repellat quas. Illo quaerat odio officia pariatur officiis dolorum facere perspiciatis excepturi! Culpa.</p>',
        ],
        comments: [
          {
            id: 25,
            date: '2022-01-01T18:25:43.511Z',
            authorId: 235,
            commentContent: 'Happy New Year!',
          },
        ],
        annCommentHelpVisible: false,
        commentsVisible: false,
      },
      {
        id: 3,
        date: '2021-08-25T18:25:43.511Z',
        authorId: 234,
        annTitle: 'Lorem ipsum dolor',
        annContent: [
          '<p>Sit amet consectetur adipisicing elit. Quod dolorem expedita harum soluta aspernatur ullam animi, ea facilis nulla. Assumenda esse, veniam illo fugit maiores numquam amet sit. </p>',
        ],
        comments: [
          {
            id: 26,
            date: '2021-11-01T18:25:43.511Z',
            authorId: 235,
            commentContent: 'Blanditiis, facere?',
          },
        ],
        annCommentHelpVisible: false,
        commentsVisible: false,
      },
    ],
    announcementsLimit: true,
    notes: [
      {
        noteContent:
          '\\( x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a} \\) Some demo note 1 <b>for lesson 2</b>, 25.08',
        lessonRef: 2,
        noteTimer: 15,
        date: '2021-08-25T18:25:43.511Z',
        noteEdit: false,
        authorId: 235,
      },
      {
        noteContent:
          '\\[ x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a} \\] Some demo note 2 <b>for lesson 2</b>, 25.09',
        lessonRef: 2,
        noteTimer: 54,
        date: '2021-09-25T18:25:43.511Z',
        noteEdit: false,
        authorId: 235,
      },
      {
        noteContent: 'Some demo note 3 <b>for lesson 2</b>, 22.08',
        lessonRef: 2,
        noteTimer: 221,
        date: '2021-08-22T18:25:43.511Z',
        noteEdit: false,
        authorId: 234,
      },
      {
        noteContent: 'Some demo note 4 <b>for lesson 2</b>, 29.08',
        lessonRef: 2,
        noteTimer: 99,
        date: '2021-08-29T18:25:43.511Z',
        noteEdit: false,
        authorId: 234,
      },
      {
        noteContent: 'Some demo note <b>for lesson 4</b>, 21.09',
        lessonRef: 5,
        noteTimer: 120,
        date: '2021-09-21T18:25:43.511Z',
        noteEdit: false,
        authorId: 235,
      },
      {
        noteContent:
          '07.01, <pre class="brush: html notranslate"><code><span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>template</span><span class="token punctuation">&gt;</span></span>' +
          '<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>div</span> <span class="token attr-name">id</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span><span class="token punctuation">"</span>app<span class="token punctuation">"</span></span><span class="token punctuation">&gt;</span></span>' +
          '<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>h1</span><span class="token punctuation">&gt;</span></span>Course app<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>h1</span><span class="token punctuation">&gt;</span></span>' +
          '<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>div</span><span class="token punctuation">&gt;</span></span>' +
          '<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>template</span><span class="token punctuation">&gt;</span></span></code></pre>',
        lessonRef: 4,
        noteTimer: 65,
        date: '2021-07-01T18:25:43.511Z',
        noteEdit: false,
        authorId: 235,
      },
    ],
    notesAddFormVisible: false,
    notesEditFormVisible: false,
    notesFilter: '',
    notesSearchFilter: '',
    newNote: '',
    // newNoteEditorData: '',
    qaAddFormVisible: false,
    qaFilter: '',
    newQuestion: '',
    editor: ClassicEditor,
    editorConfig: {
      toolbar: {
        // plugins: [EssentialsPlugin],
        items: [
          'heading',
          'blockQuote',
          'bold',
          'italic',
          'numberedList',
          'bulletedList',
          'math',
        ],
      },
      math: {
        engine: 'katex',
        outputType: 'span',
      },
      // codeBlock: {
      //   languages: [
      //     { language: 'plaintext', label: 'Plain text', class: '' },
      //     { language: 'php', label: 'PHP', class: 'php-code' },
      //     {
      //       language: 'javascript',
      //       label: 'JavaScript',
      //       class: 'js javascript js-code',
      //     },
      //     { language: 'python', label: 'Python' },
      //   ],
      // },
    },
    currentNotesFilterId: 1,
    showNotesFilterPopper: null,
    notesFilter: [
      {
        id: 1,
        label: 'All lectures',
      },
      {
        id: 2,
        label: 'Current lecture',
      },
    ],
    currentNotesSortId: 1,
    showNotesSortPopper: null,
    notesSort: [
      {
        id: 1,
        label: 'Sort by most recent',
      },
      {
        id: 2,
        label: 'Sort by oldest',
      },
    ],
    chapters: [
      {
        id: 1,
        chapterTitle: 'Getting Started',
        isExpanded: false,
        lessons: [
          {
            lessonId: 1,
            lessonTitle: 'Lorem, ipsum dolor sit amet',
            posterPath: 'assets/images/posters/poster-1.jpg',
            videoPath: 'assets/media/CH_04_Intro_01.mp4',
            lessonDuration: 6,
            completed: false,
            videoDialogs: [
              {
                id: 1001,
                title: 'dialog 11 title',
                content: '<p>Some modal text 11</p>',
                visible: false,
              },
              {
                id: 1002,
                title: 'dialog 12 title',
                content: '<p>Some modal text 12</p>',
                visible: false,
              },
              {
                id: 1003,
                title: 'dialog 13 title',
                content: '<p>Some modal text 13</p>',
                visible: false,
              },
            ],
            qa: [
              {
                id: 1001,
                question: 'Cumque, excepturi qui',
                answer:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum est iusto, similique numquam veritatis ex accusamus quas recusandae quaerat asperiores esse fugiat reprehenderit dolores veniam, debitis tenetur quam ratione alias?',
              },
              {
                id: 1002,
                question: 'Rerum est iusto, similique numquam veritatis',
                answer:
                  'Nisi optio voluptate repellendus cupiditate officia aliquam facere unde adipisci corporis mollitia, incidunt necessitatibus, recusandae eius repellat omnis. Culpa labore repellat recusandae.',
              },
              {
                id: 1003,
                question: 'Sit molestias pariatur',
                answer:
                  'Earum reprehenderit labore pariatur odio. Libero laboriosam a earum in mollitia aliquam autem architecto nesciunt nobis? Molestias, recusandae vero. Delectus, saepe ducimus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis velit ipsam illum rerum dolore ipsum provident aliquam maiores magnam! Sit molestias pariatur id earum ipsam dicta, repudiandae error rerum iusto!',
              },
            ],
          },
          {
            lessonId: 2,
            lessonTitle: 'Illum reiciendis accusamus maiores',
            posterPath: 'assets/images/posters/poster-2.jpg',
            videoPath: 'assets/media/CH_04_Intro_02.mp4',
            lessonDuration: 5,
            videoDialogs: [
              {
                id: 2001,
                title: 'dialog 21 title',
                content: '<p>Some modal text 21</p>',
                visible: false,
              },
              {
                id: 2002,
                title: 'dialog 22 title',
                content: '<p>Some modal text 22</p>',
                visible: false,
              },
              {
                id: 2003,
                title: 'dialog 23 title',
                content: '<p>Some modal text 23</p>',
                visible: false,
              },
            ],
            qa: [
              {
                id: 2001,
                question: 'Sit molestias pariatur',
                answer:
                  'Earum reprehenderit labore pariatur odio. Libero laboriosam a earum in mollitia aliquam autem architecto nesciunt nobis? Molestias, recusandae vero. Delectus, saepe ducimus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis velit ipsam illum rerum dolore ipsum provident aliquam maiores magnam! Sit molestias pariatur id earum ipsam dicta, repudiandae error rerum iusto!',
              },
              {
                id: 2002,
                question: 'Rerum est iusto, similique numquam veritatis',
                answer:
                  'Nisi optio voluptate repellendus cupiditate officia aliquam facere unde adipisci corporis mollitia, incidunt necessitatibus, recusandae eius repellat omnis. Culpa labore repellat recusandae.',
              },
            ],
          },
          {
            lessonId: 3,
            lessonTitle: 'Reference files',
            posterPath: 'assets/images/posters/poster-2.jpg',
            videoPath: 'assets/media/CH_04_Intro_02.mp4',
            lessonDuration: 1,
            resources: [
              {
                fileName: 'header.html',
                filePath: 'templates/header.html',
              },
              {
                fileName: 'logo.png',
                filePath: 'assets/images/logo.png',
              },
            ],
            completed: false,
            videoDialogs: [],
            qa: [
              {
                id: 3001,
                question: 'Delectus, saepe ducimus',
                answer:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum est iusto, similique numquam veritatis ex accusamus quas recusandae quaerat asperiores esse fugiat reprehenderit dolores veniam, debitis tenetur quam ratione alias?',
              },
              {
                id: 3002,
                question: 'Libero laboriosam a earum',
                answer:
                  'Nisi optio voluptate repellendus cupiditate officia aliquam facere unde adipisci corporis mollitia, incidunt necessitatibus, recusandae eius repellat omnis. Culpa labore repellat recusandae.',
              },
              {
                id: 3003,
                question: 'Sit molestias pariatur',
                answer:
                  'Debitis velit ipsam illum rerum dolore ipsum provident aliquam maiores magnam! Sit molestias pariatur id earum ipsam dicta, repudiandae error rerum iusto! Earum reprehenderit labore pariatur odio. Libero laboriosam a earum in mollitia aliquam autem architecto nesciunt nobis? Molestias, recusandae vero. Delectus, saepe ducimus. Lorem ipsum dolor sit amet consectetur adipisicing elit. ',
              },
            ],
          },
        ],
      },
      {
        id: 2,
        chapterTitle: 'Advanced Features',
        isExpanded: false,
        lessons: [
          {
            lessonId: 4,
            lessonTitle: 'Quaerat, nam',
            posterPath: 'assets/images/posters/poster-4.jpg',
            videoPath: 'assets/media/CH_04_Intro_03.mp4',
            lessonDuration: 4,
            completed: true,
            videoDialogs: [],
            qa: [],
          },
          {
            lessonId: 5,
            lessonTitle: 'Maiores quaerat totam',
            posterPath: 'assets/images/posters/poster-5.jpg',
            videoPath: 'assets/media/CH_04_INTRO_04.mp4',
            lessonDuration: 6,
            completed: false,
            videoDialogs: [
              {
                id: 5001,
                title: 'dialog 51 title',
                content: '<p>Some modal text 51</p>',
                visible: false,
              },
            ],
            qa: [
              {
                id: 3001,
                question: 'Delectus, saepe ducimus',
                answer:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum est iusto, similique numquam veritatis ex accusamus quas recusandae quaerat asperiores esse fugiat reprehenderit dolores veniam, debitis tenetur quam ratione alias?',
              },
              {
                id: 3002,
                question: 'Libero laboriosam a earum',
                answer:
                  'Nisi optio voluptate repellendus cupiditate officia aliquam facere unde adipisci corporis mollitia, incidunt necessitatibus, recusandae eius repellat omnis. Culpa labore repellat recusandae.',
              },
            ],
          },
          {
            lessonId: 6,
            lessonTitle: 'Lesson with video and resources',
            posterPath: 'assets/images/posters/poster-6.jpg',
            videoPath: 'assets/media/CH_04_INTRO_03.mp4',
            lessonDuration: 7,
            resources: [
              {
                fileName: 'scripts.html',
                filePath: 'templates/scripts.html',
              },
            ],
            completed: false,
            qa: [],
          },
        ],
      },
    ],

    authors: [
      {
        id: 234,
        name: 'Edwin Diaz',
        profile: 'Web Developer & Premium Instructor - 700,000 students',
        userpic: 'assets/images/userpic.png',
        contacts: [
          {
            icon: 'twitter',
            link: 'https://twitter.com',
          },
          {
            icon: 'link',
            link: 'https://google.com',
          },
        ],
        description: [
          '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non repellendus in quidem unde, accusamus adipisci deserunt fuga consequatur saepe ea sed consequuntur optio dolores dolorem at aperiam neque vitae distinctio!</p>',
          '<p>Earum iure dolore itaque, accusantium doloribus sunt suscipit ratione eum adipisci illum numquam debitis velit sapiente quas, cumque explicabo. Voluptates, sequi ipsam. Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>',
          '<p>Eligendi quis mollitia architecto velit, delectus libero nisi repudiandae voluptatibus. Quisquam quasi asperiores consequatur quas, labore aliquid animi distinctio possimus odit saepe.</p>',
        ],
      },
      {
        id: 235,
        name: 'John Edwards',
        profile: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        userpic: 'assets/images/userpic.png',
        contacts: [
          {
            icon: 'twitter',
            link: 'https://twitter.com',
          },
        ],
        description: [
          '<p>Eligendi quis mollitia architecto velit, delectus libero nisi repudiandae voluptatibus. Quisquam quasi asperiores consequatur quas, labore aliquid animi distinctio possimus odit saepe.</p>',
        ],
      },
    ],
    currentYear: new Date().getFullYear(),
    window: {
      width: 0,
      height: 0,
    },
    currentLanguage: 'english',
  }),
  created() {
    for (const chapter of this.chapters) {
      for (const lesson of chapter.lessons) {
        if (lesson.lessonId === this.currentLessonId) {
          this.currentChapterId = chapter.id;
          // this.currentLessonId = lesson.lessonId;
          this.currentLessonUrl = lesson.videoPath;
          this.currentPosterUrl = lesson.posterPath;
          chapter.isExpanded = true;
          return;
        }
      }
    }

    var player = videojs('video-element', {
      autoplay: 'any',
    });
  },
  components: {
    Popper,
  },

  mounted() {
    let currData = JSON.parse(localStorage.getItem(this.courseTitle));

    if (currData) {
      this.currentChapterId = currData.current_chapter_id;
      this.currentLessonId = currData.current_lesson_id;
      this.currentLessonSecond = currData.current_lesson_second;
      this.videoOptions.starttime = this.currentLessonSecond;
    }

    let that = this;

    this.videoPlayer = videojs(
      this.$refs.videoPlayer,
      this.videoOptions,
      function onPlayerReady() {
        that.videoPlayer.on('useractive', function () {
          that.userIsActive = true;
        });
        that.videoPlayer.on('userinactive', function () {
          that.userIsActive = false;
        });
      }
    );

    let tmpCurrentLessonSecond = this.currentLessonSecond;
    this.videoPlayer.on('loadedmetadata', function () {
      that.videoPlayer.currentTime(tmpCurrentLessonSecond);
    });

    this.videoPlayer.markers({
      markerStyle: {
        width: '6px',
        height: '12px',
        'border-radius': 0,
        'background-color': '#666',
      },
      markerTip: {
        text: function text(marker) {
          return marker.text;
        },
        html: function (marker) {
          return marker.text;
        },
      },
      onMarkerClick: function onMarkerClick(marker) {
        that.videoPlayer.pause();
      },
      markers: this.getCurrVideoNotes(),
    });

    this.addButtonAddNote();
    this.addButtonCustomMenu();
    this.addButtonExpandedView();

    let srcs = [];
    for (const chapter of this.chapters) {
      for (const lesson of chapter.lessons) {
        if (lesson.videoPath) {
          srcs.push({
            sources: [{ src: lesson.videoPath }],
            poster: lesson.posterPath,
          });
        }
      }
    }
    this.videoPlayer.playlist(srcs);
    let tmpIndex = this.videoPlayer.playlist.indexOf(this.currentLessonUrl);
    if (-1 != tmpIndex) {
      this.videoPlayer.playlist.currentItem(tmpIndex);
    }
    // Play through the playlist automatically.
    this.videoPlayer.playlist.autoadvance(this.videoPlayNextTimeout);

    this.videoPlayer.on('playing', function (e) {
      setInterval(function () {
        let data = {
          current_chapter_id: that.currentChapterId,
          current_lesson_id: that.currentLessonId,
          current_lesson_second: that.currentLessonSecond,
          lesson_second: Math.floor(that.videoPlayer.currentTime()),
        };
        localStorage.setItem(that.courseTitle, JSON.stringify(data));
      }, 1000);
    });

    this.videoPlayer.on('touchstart', function (e) {
      e.preventDefault();
      if (e.target.nodeName === 'VIDEO') {
        if (that.videoIsPlaying) {
          that.videoPlayer.pause();
        } else {
          that.videoPlayer.play();
        }
      }
    });
    // this.videoPlayer.on(
    //   'tap',
    //   (e) => {
    //     e.preventDefault();
    //     console.log('tap');
    //     if (this.videoPlayer.paused) {
    //       this.videoPlayer.play();
    //     } else {
    //       this.videoPlayer.pause();
    //     }
    //   },
    //   { passive: false }
    // );

    // Sidebar
    this.expandActiveChapter();

    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
    this.checkSidebar();

    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    isLastLesson() {
      return (
        this.currentLessonId ==
        this.chapters[this.chapters.length - 1].lessons[
          this.chapters[this.chapters.length - 1].lessons.length - 1
        ].lessonId
      );
    },
    lessonTitle() {
      return (lid = this.currentLessonId) => {
        const ch = this.chapters.find((chapter) =>
          chapter.lessons.some((lesson) => lesson.lessonId === lid)
        );
        const l = ch.lessons.find((lesson) => lesson.lessonId === lid);
        return l.lessonId + '. ' + l.lessonTitle;
      };
    },
    currentDialogs() {
      return this.getCurrentLesson().videoDialogs;
    },
    noteTitle() {
      return (lid) => {
        const ch = this.chapters.find((chapter) =>
          chapter.lessons.some((lesson) => lesson.lessonId === lid)
        );
        const l = ch.lessons.find((lesson) => lesson.lessonId === lid);
        return (
          '<span class="note-chapter">' +
          ch.id +
          '. ' +
          ch.chapterTitle +
          '</span> <span class="note-lesson">' +
          l.lessonId +
          '. ' +
          l.lessonTitle +
          '</span>'
        );
      };
    },
    filteredNotes() {
      let cNotes = {};
      if (this.currentNotesSortId == 1) {
        // recent
        cNotes = this.notes.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else {
        cNotes = this.notes.sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      if (this.currentNotesFilterId == 2) {
        // this lesson
        cNotes = this.notes.filter((a) => a.lessonRef == this.currentLessonId);
      }

      // search
      const filteredList = this.notesSearchFilter
        ? cNotes.filter((q) => {
            // const keyword = '';
            // const mathContent = katex.renderToString(q.noteContent);
            // console.log(mathContent);
            // return mathContent
            // return katex.renderToString(q.noteContent);

            return q.noteContent
              .toLowerCase()
              .includes(this.notesSearchFilter.toLowerCase());
          })
        : cNotes;

      const hightlightedLIst = filteredList.map((o) => ({
        noteContent: o.noteContent.replace(
          new RegExp(this.notesSearchFilter, 'gi'),
          (str) => `<mark>${str}</mark>`
        ),
        lessonRef: o.lessonRef,
        noteTimer: o.noteTimer,
        date: o.date,
        noteEdit: o.noteEdit,
        authorId: o.authorId,
      }));

      // console.log(cNotes);
      // this.renderMath();

      // return filteredList;
      return this.notesSearchFilter ? hightlightedLIst : filteredList;
    },
    filteredQuestions() {
      const currentQA = this.getCurrentLesson().qa;

      const filteredList = this.qaFilter
        ? currentQA.filter((q) => {
            // const keyword = '';
            return (
              q.question.toLowerCase().includes(this.qaFilter.toLowerCase()) ||
              q.answer.toLowerCase().includes(this.qaFilter.toLowerCase())
            );
          })
        : currentQA;
      const hightlightedLIst = filteredList.map((o) => ({
        question: o.question.replace(
          new RegExp(this.qaFilter, 'gi'),
          (str) => `<mark>${str}</mark>`
        ),

        answer: o.answer.replace(
          new RegExp(this.qaFilter, 'gi'),
          (str) => `<mark>${str}</mark>`
        ),
      }));
      return this.qaFilter ? hightlightedLIst : filteredList;
    },
  },
  watch: {
    // currentDataTab(value, oldValue) {
    //   console.log('currentDataTab: ' + value);
    //   // save(value);
    //   const that = this;
    //   setTimeout(function () {
    //   }, 200);
    // },
    notesSearchFilter() {
      this.renderMath();
      // console.log('notesSearchFilter: ' + this.notesSearchFilter);
    },
  },
  setup() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
  },
  updated() {
    this.$nextTick(() => {
      this.renderMath();
    });
    // this.$nextTick(() => {
    //   if (this.currentDataTab === 2) {
    //   }
    // });
  },
  methods: {
    // Header
    updateProgress() {
      let val =
        (this.getCompletedLessonsNum() * 100) / this.getTotalLessonsNum();
      let circle = document.querySelector('#progressBar');
      let r = circle.getAttribute('r');
      let c = Math.PI * (r * 2);
      // if (val < 0) {val = 0;}
      // if (val > 100) {val = 100;}
      var pct = ((100 - val) / 100) * c;
      circle.style.strokeDashoffset = pct;
    },
    getCompletedLessonsNum() {
      let n = 0;
      for (const chapter of this.chapters) {
        for (const lesson of chapter.lessons) {
          if (lesson.completed) n++;
        }
      }
      return n;
    },
    getTotalLessonsNum() {
      let n = 0;
      for (const chapter of this.chapters) {
        for (const lesson of chapter.lessons) {
          n++;
        }
      }
      return n;
    },

    // Video
    startPlayer(lessonId, time = 0, event) {
      for (const chapter of this.chapters) {
        for (const lesson of chapter.lessons) {
          if (lesson.lessonId === lessonId) {
            this.currentChapterId = chapter.id;
            this.currentLessonId = lesson.lessonId;
            this.currentLessonUrl = lesson.videoPath;
            this.currentPosterUrl = lesson.posterPath;
            this.videoPlayer.src(this.currentLessonUrl);
            this.videoPlayer.poster(this.currentPosterUrl);

            this.currentLessonSecond = time;
            this.videoPlayer.currentTime(time);
            this.updateVideoMarkers();
            this.expandActiveChapter();
            this.updateButtonCustomMenu();

            return;
          }
        }
      }
    },
    updateVideoMarkers() {
      let player = this.videoPlayer;
      // console.log(this.videoPlayer.markers);
      this.videoPlayer.markers.reset(this.getCurrVideoNotes());
      // player.markers({
      //   markers: [], // fthis.videoPlayer.markersthis.getCurrVideoNotes(),
      // });
    },
    addButtonAddNote() {
      let Button = videojs.getComponent('Button');
      let that = this;
      let addNoteButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.controlText('Add Note');
          this.addClass('vjs-addnote');
        },
        handleClick: function () {
          that.videoPlayer.pause();
          that.currentDataTab = 2;
          that.notesAddFormVisible = true;

          setTimeout(() => {
            const addFormY = document
              .querySelector('.course-data')
              .getBoundingClientRect().top;
            window.scrollTo({
              top: addFormY,
              behavior: 'smooth',
            });
          }, 100);
        },
      });
      videojs.registerComponent('addNoteButton', addNoteButton);
      that.videoPlayer.getChild('controlBar').addChild('addNoteButton', {});
    },

    addButtonCustomMenu() {
      let MenuButton = videojs.getComponent('MenuButton');
      var MenuItem = videojs.getComponent('MenuItem');
      let that = this;
      class CustomMenuButton extends MenuButton {
        constructor(player, options) {
          super(player, options);
          // MenuButton.apply(this, arguments);
          this.controlText('Menu');
          this.addClass('vjs-custommenu');
        }
        createItems() {
          const player = this.player();
          return this.options().customMenuItems.map(function (i) {
            var item = new MenuItem(player, { label: i.name });
            item.handleClick = function () {
              that.showVideoDialog(i.id);
            };
            return item;
          });
        }
      }
      videojs.registerComponent('CustomMenuButton', CustomMenuButton);
      const origMenuItems = this.getCurrentLesson().videoDialogs;
      let currentMenuItems = [];
      // console.log(currentMenuItems);
      if (typeof origMenuItems !== 'undefined' && origMenuItems.length) {
        currentMenuItems = origMenuItems.map((i) => ({
          id: i.id,
          name: i.title,
        }));
      }

      that.videoPlayer.getChild('controlBar').addChild('CustomMenuButton', {
        customMenuItems: currentMenuItems,
      });
    },
    updateButtonCustomMenu() {
      this.videoPlayer.getChild('controlBar').removeChild('CustomMenuButton');
      this.addButtonCustomMenu();
    },

    showVideoDialog(currentId) {
      const dialogs = this.getCurrentLesson().videoDialogs;
      const activeDialog = dialogs.find((d) => d.id === currentId);
      activeDialog.visible = true;
    },

    addButtonExpandedView() {
      let Button = videojs.getComponent('Button');
      let that = this;
      let expandedViewButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.controlText('Expanded View');
          this.addClass('vjs-expandedview');
        },
        handleClick: function () {
          that.toggleExpandedView();
        },
      });
      videojs.registerComponent('expandedViewButton', expandedViewButton);
      that.videoPlayer
        .getChild('controlBar')
        .addChild('expandedViewButton', {});
    },

    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
      this.isExpandedView = !this.isExpandedView;
      this.checkActiveTab();
    },
    toggleExpandedView() {
      this.sidebarVisible = !this.sidebarVisible;
      this.isExpandedView = !this.isExpandedView;
      this.checkActiveTab();
    },
    checkSidebar() {
      if (this.window.width < 992) {
        this.sidebarVisible = false;
      }
      this.checkActiveTab();
    },

    onPlayerPlay(player) {
      console.log('currentLessonId: ' + this.currentLessonId);
      console.log(this.videoPlayer.playlist);
      this.videoIsPlaying = true;

      // show animated play
      const playButton = document.querySelector('.video-animated');
      const animDivPlay = document.createElement('div');
      animDivPlay.id = 'animDivPlay';
      const animDivPause = document.getElementById('animDivPause');
      if (animDivPause) playButton.appendChild(animDivPlay);
      if (animDivPause) playButton.removeChild(animDivPause);

      this.videoPlayNext = false;
    },
    onPlayerPause(player) {
      this.videoIsPlaying = false;

      // show animated pause
      const playButton = document.querySelector('.video-animated');
      const animDivPause = document.createElement('div');
      animDivPause.id = 'animDivPause';
      const animDivPlay = document.getElementById('animDivPlay');
      playButton.appendChild(animDivPause);
      if (animDivPlay) playButton.removeChild(animDivPlay);
    },
    onPlayerEnded(player) {
      this.videoIsPlaying = false;
      this.videoPlayNext = true;
      this.videoPlayNextCountdown();
    },
    onPlayerTimeupdate(player) {
      this.currentLessonSecond = Math.floor(this.videoPlayer.currentTime());
    },
    getAuthorIndex(uid) {
      let userId = uid ? uid : this.summary.instructor.instructorID;
      return this.authors.findIndex((obj) => obj.id === userId);
    },
    getCurrentLesson() {
      let ch = this.chapters.find((chapter) =>
        chapter.lessons.some(
          (lesson) => lesson.lessonId === this.currentLessonId
        )
      );
      return ch.lessons.find(
        (lesson) => lesson.lessonId === this.currentLessonId
      );
    },

    debug(event) {
      console.log(event.target.name);
    },
    cancelPlayNextVideo() {
      this.videoPlayer.playlist.autoadvance();
      this.videoPlayNext = false;
      // const playNextPopup = document.querySelector('#play-next-video-popup');
      // if (playNextPopup !== null) playNextPopup.style.display = 'none';
    },
    // playNextVideo() {
    //   this.videoPlayer.playlist.next();
    //   const playNextPopup = document.querySelector('#play-next-video-popup');
    //   if (playNextPopup !== null) playNextPopup.style.display = 'none';
    // },

    videoPlayNextCountdown() {
      clearInterval(this.videoPlayNextInterval);
      this.videoPlayNextTimer = this.videoPlayNextTimeout;
      if (this.videoPlayNext) {
        this.videoPlayNextInterval = setInterval(() => {
          this.videoPlayNextTimer--;
          if (!this.videoPlayNext) {
            clearInterval(this.videoPlayNextInterval);
          }
        }, 1000);
      }
    },

    getCurrVideoNotes() {
      let currVideoNotes = [];
      for (const note of this.notes) {
        const isOwnNote = note.authorId == this.currentUserId;
        if (note.lessonRef == this.currentLessonId) {
          currVideoNotes.push({
            time: note.noteTimer,
            text: note.noteContent.replace(/<[^>]*>?/gm, ''),
            isOwn: isOwnNote ? true : false,
          });
        }
      }
      return currVideoNotes;
    },

    // Notes

    getCurrentNotesFilter() {
      return this.notesFilter.find((f) => f.id === this.currentNotesFilterId)
        .label;
    },
    getCurrentNotesSort() {
      return this.notesSort.find((s) => s.id === this.currentNotesSortId).label;
    },

    hideNotesFilterPopper() {
      this.showNotesFilterPopper = false;
      this.$nextTick(() => {
        this.showNotesFilterPopper = null;
      });
    },

    hideNotesSortPopper() {
      this.showNotesSortPopper = false;
      this.$nextTick(() => {
        this.showNotesSortPopper = null;
      });
    },
    addNote() {
      this.notes.push({
        noteContent: this.newNote,
        lessonRef: this.currentLessonId,
        noteTimer: this.currentLessonSecond,
        date: new Date(),
        noteEdit: false,
      });
      this.newNote = '';
      this.updateVideoMarkers();

      this.renderMath();
    },
    editNote(n) {
      n.noteEdit ^= true;
    },
    saveNote(n) {
      n.noteEdit ^= true;
    },

    jumpToNote(lid, timer) {
      this.startPlayer(lid, timer);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    renderMath() {
      setTimeout(function () {
        renderMathInElement(document.body);
      }, 1000);
    },

    searchHandler() {
      console.log('searchHandler');
      this.renderMath();
    },

    // Announcements

    timeAgo(p) {
      const current = new Date();
      const previous = new Date(p);
      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;
      const msPerDay = msPerHour * 24;
      const msPerMonth = msPerDay * 30;
      const msPerYear = msPerDay * 365;

      const elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
      } else if (elapsed < msPerHour) {
        let num = Math.round(elapsed / msPerMinute);
        return (num == 1 ? 'a minute' : num + ' minutes') + ' ago';
      } else if (elapsed < msPerDay) {
        let num = Math.round(elapsed / msPerHour);
        return (num == 1 ? 'a hour' : num + ' hours') + ' ago';
      } else if (elapsed < msPerMonth) {
        let num = Math.round(elapsed / msPerDay);
        return (num == 1 ? 'a day' : num + ' days') + ' ago';
      } else if (elapsed < msPerYear) {
        let num = Math.round(elapsed / msPerMonth);
        return (num == 1 ? 'a month' : num + ' months') + ' ago';
      } else {
        let num = Math.round(elapsed / msPerYear);
        return (num == 1 ? 'a year' : num + ' years') + ' ago';
      }
    },

    announcementsLimited() {
      return this.announcementsLimit
        ? this.announcements.slice(0, 2)
        : this.announcements;
    },

    // Searches
    // displayMatches(e) {
    //     var regex = new RegExp(e.target.value, 'gi')
    //     var response = text.innerText.replace(regex, function(str) {
    //         return "<span style='background-color: yellow;'>" + str + "</span>"
    //     })
    //     text.innerHTML = response
    // }

    // Questions
    addQuestion() {
      let ch = this.chapters.find((chapter) =>
        chapter.lessons.some(
          (lesson) => lesson.lessonId === this.currentLessonId
        )
      );
      // ch.lessons[this.currentLessonId].qa;
      const currentQuestions = this.getCurrentLesson().qa;
      console.log(ch.lessons);
      const newId = currentQuestions[currentQuestions.length - 1].id + 1;
      currentQuestions.push({
        id: newId,
        question: this.newQuestion,
        answer: '',
      });
      this.qaAddFormVisible = false;
    },

    // Sidebar
    expandActiveChapter() {
      const ch = this.chapters.find((chapter) =>
        chapter.lessons.some(
          (lesson) => lesson.lessonId === this.currentLessonId
        )
      );
      ch.isExpanded = true;
    },
    getChapterProgress(ch) {
      let p = 0;
      for (const lesson of ch.lessons) {
        if (lesson.completed) p++;
      }
      return p + ' / ' + ch.lessons.length;
    },
    getChapterDuration(ch) {
      let d = 0;
      for (const lesson of ch.lessons) {
        d += lesson.lessonDuration;
      }
      return d + ' min';
    },
    getCurrentTime(time) {
      return (
        Math.trunc(time / 60) +
        ':' +
        (time % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })
      );
    },
    isChapterExpanded(ch) {
      return ch.lessons.some(
        (lesson) => lesson.lessonId === this.currentLessonId
      );
    },

    // Resize
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      if (this.window.width < 992) {
        this.sidebarVisible = false;
      }
      if (this.window.width > 991) {
        this.sidebarVisible = this.isExpandedView ? false : true;
      }
      this.checkActiveTab();
    },

    checkActiveTab() {
      if (this.currentDataTab == 0 && this.window.width > 991) {
        this.currentDataTab = 1;
      }
    },
  },
};
